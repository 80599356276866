<template>
  <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
    <div class="font-weight-bold mb-3">{{ act.title }}</div>
    <v-col cols="12" class="pa-0">
      <v-card elevation="0">
        <template v-if="is_scheduled.final">
          <v-responsive :aspect-ratio="16 / 9" style="position: relative;">
            <vue-plyr id="videoElement" ref="plyr" seekTime="1" :options="option" preload="auto">
              <video
                id="video-test"
                width="100%"
                height="auto"
                preload="auto"
                controls
                playsinline
                ref="video_test"
                controlslist="nodownload noremoteplayback noplaybackrate"
                data-poster="poster.jpg"
                class="video-test"
                >
                <!-- @ended="handleEnded" -->
                <source :src="videoUrl" type="video/mp4" />
              </video>
            </vue-plyr>
            <!-- make selection resolution video -->
            <div v-if="isShowResolutionConfig" class="d-flex justify-center align-center" :style="$vuetify.breakpoint.name == 'xs' ? 'position: absolute; top: 10px; left: 15px;' : 'position: absolute; top: 10px; left: 15px;'">
              <v-menu
                bottom
                offset-y
                nudge-bottom="10">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#22B2F9"
                    class="white--text"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="15" left>ri-equalizer-line</v-icon>
                    Kualitas
                  </v-btn>
                </template>
  
                <v-list class="py-0">
                  <v-list-item
                    v-for="(item, index) in resolutions"
                    :key="index"
                    :style="item.selected ? 'background-color: #22B2F9;' : ''"
                    :class="item.selected ? 'white--text' : ''"
                    @click="confirmationResolution(item)">
                    <v-list-item-content>
                      <v-list-item-title :class="item.selected ? 'font-weight-bold text-capitalize subtitle-2' : 'font-weight-medium text-capitalize subtitle-2'">
                        {{ item.label == 'original' ? 720 : item.label }}p
                      </v-list-item-title>
                      <v-list-item-subtitle :class="item.selected ? 'white--text caption' : 'caption'">Resolusi {{ item.label == 'original' ? 'original' : item.label + 'p' }} {{ item.label == 'original' ? '(720p)' : '' }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-responsive>
          <div class="d-flex justify-space-between align-center my-3 mx-3"  v-if="act.quiz != null && act.quiz.setting.show_explanation && act.is_complete">
            <div class="subtitle-2">Anda dapat melihat kembali pembahasan pada pop up quiz : </div>
            <v-btn
              x-small
              @click="$emit('click:showGameplay')"
              color="primary" 
              class="text-right">
              Lihat Pembahasan Pop Up Quiz
            </v-btn>
          </div>
          <v-divider></v-divider>
          <div id="desc-video" class="pa-4">
            <p
              class="text-body-2 text-justify mb-4"
              v-html="act.theory.description">
            </p>
          </div>
        </template>
        <!-- <div v-else-if="is_scheduled.day_date" class="pa-4">
          <div>Video akan bisa diakses pada:</div>
          <div
            class="font-weight-bold"
            v-for="(item, index) in schedules"
            :key="index"
          >
            <template
              v-if="
                setting.schedules_data.is_day == '1' &&
                days[timeIsIt.day] == item.day
              "
            >
              <span> Hari {{ day(item.day) }}, </span>
              <template v-for="(time, index2) in sortTimes(item.times)">
                <span
                  :key="index2"
                  v-if="
                    current_hour <= time.start_time &&
                    (!item.times[index2 - 1] ||
                      (item.times[index2 - 1] &&
                        item.times[index2 - 1].end_time > current_hour))
                  "
                >
                  pukul {{ time.start_time }} - {{ time.end_time }} WIB<br />
                </span>
              </template>
            </template>
            <template
              v-else-if="
                setting.schedules_data.is_day == '2' &&
                current_date == item.date.slice(0, 10)
              "
            >
              <span> Tanggal {{ item.date }}, </span>
              <template v-for="(time, index2) in sortTimes(item.times)">
                <span
                  :key="index2"
                  v-if="
                    current_hour <= time.start_time &&
                    (!item.times[index2 - 1] ||
                      (item.times[index2 - 1] &&
                        item.times[index2 - 1].end_time > current_hour))
                  "
                >
                  pukul {{ time.start_time }} - {{ time.end_time }} WIB<br />
                </span>
              </template>
            </template>
          </div>
        </div> -->
        <div v-else-if="setting.is_show_scheduled == '1'" class="pa-4">
           <div id="desc-video">
            <p
              class="text-justify mb-4"
              v-html="act.theory.description"
            ></p>
          </div>
          <div>Video akan bisa diakses pada:</div>
          <div
            class="font-weight-bold"
            v-for="(item, index) in schedules"
            :key="index"
          >
            <template v-if="setting.schedules_data.is_day == '1'">
              <span>Hari {{ day(item.day) }}</span>
              <ul>
                <li
                  v-for="(time, index2) in sortTimes(item.times)"
                  :key="index2"
                >
                  Pukul {{ time.start_time }} - {{ time.end_time }} WIB<br />
                </li>
              </ul>
            </template>
            <span v-else-if="setting.schedules_data.is_day == '2'">
              <span>Tanggal {{ date(item.date) }}</span>
              <ul>
                <li
                  v-for="(time, index2) in sortTimes(item.times)"
                  :key="index2"
                >
                  Pukul {{ time.start_time }} - {{ time.end_time }} WIB<br />
                </li>
              </ul>
            </span>
          </div>
        </div>
      </v-card>
    </v-col>

    <v-dialog v-model="dialog" width="400">
      <v-card class="rounded-lg">
        <v-card-title>
          Konfirmasi Ganti Resolusi
        </v-card-title>
        <v-card-text class="text-center">
          Anda yakin ingin mengubah resolusi video?
          <br/>
          Jika perubahan resolusi dilakukan, maka video ini akan diputar ulang kembali dari awal.
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="" small depressed @click="dialog = false">
            Batal
          </v-btn>
          <v-btn color="primary" small depressed @click="changeResolution(resolutionNew)">
            Ya, Lanjutkan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  props: {
    act: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      resolution: "original",
      resolutionNew: null,
      dialog: false,
      videoUrl: null,
      resolutions: [],
    };
  },
  computed: {
    // currentTime() {
    //   return this.$refs.plyr.player.currentTime;
    // },
    // currentDuration() {
    //   return this.$refs.plyr.player.duration;
    // },
    isShowResolutionConfig() {
      return this.act.type === 'menonton_video' && (this.act?.theory?.file_variants?.length > 1)
    },
    timeIsIt() {
      const theDate = new Date();

      return {
        day: theDate.getDay(),
        date: theDate.getDate().toString().padStart(2, 0),
        month: (theDate.getMonth() + 1).toString().padStart(2, 0),
        year: theDate.getFullYear(),
        hour: theDate.getHours().toString().padStart(2, 0),
        minute: theDate.getMinutes().toString().padStart(2, 0),
      };
    },
    days() {
      return [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
    },
    option: () => ({
      keyboard: {
        focused: false,
        global: false,
      },
      seekTime: 1,
      displayDuration: false,
      mediaMetadata: { title: "", artist: "", album: "", artwork: [] },
    }),
    setting() {
      return (this.act?.theory?.meta_data || []).reduce((car, val) => {
        if (["schedules_data"].includes(val.title))
          car[val.title] = JSON.parse(val.content);
        else car[val.title] = val.content;
        return car;
      }, {});
    },
    current_hour() {
      return `${this.timeIsIt.hour}:${this.timeIsIt.minute}`;
    },
    current_date() {
      return `${this.timeIsIt.year}-${this.timeIsIt.month}-${this.timeIsIt.date}`;
    },
    is_scheduled() {
      let day_date = false,
        time = false,
        final = false;
      if (this.setting.is_scheduled == 1) {
        const schedules_data = this.setting?.schedules_data || {};
        const currentDay = this.timeIsIt.day;
        const currentDate = this.current_date;
        const currentHour = this.current_hour;

        switch (schedules_data.is_day) {
          case "1": // untuk hari
            final = schedules_data.days.some((val) => {
              if (this.days.findIndex((v) => v == val.day) == currentDay) {
                day_date = true;
                return val.times.some((val2) => {
                  // console.log(currentHour, val2.start_time, val2.end_time);
                  if (
                    currentHour >= val2.start_time &&
                    currentHour <= val2.end_time
                  ) {
                    time = true;
                    return true;
                  }
                });
              }
            });
            break;
          case "2": // untuk tanggal
            final = schedules_data.dates.some((val) => {
              if (currentDate == val.date.slice(0, 10)) {
                day_date = true;
                return val.times.some((val2) => {
                  if (
                    currentHour >= val2.start_time &&
                    currentHour <= val2.end_time
                  ) {
                    time = true;
                    return true;
                  }
                });
              }
            });
            break;
          default:
            break;
        }
      } else {
        day_date = time = final = true;
      }
      return { day_date, time, final };
    },
    schedules() {
      if (this.setting.schedules_data.is_day == "1") {
        return this.setting.schedules_data.days;
      } else if (this.setting.schedules_data.is_day == "2") {
        return this.setting.schedules_data.dates;
      }
      return [];
    },
  },
  watch: {

  },
  mounted() {
    this.useOnMoveMinimize();
    this.useOnMoveTab();
    this.video_url();

    // END VIDEO HANDLE
    this.$refs.plyr.player.on('ended', this.handleEnded);
  },
  beforeDestroy() {
    document.onblur = null;
    document.onvisibilitychange = null;
  },
  methods: {
    video_url() {
      // check if video is setting show video control
      const plyr__controls = document.getElementsByClassName('plyr__progress__container');
      const plyr__controls__item = document.getElementsByClassName('plyr__controls__item plyr__control');

      const plyr_pip = plyr__controls__item[2];

      // remove pip
      plyr_pip.remove();
      
      
      if(!this.act?.settings?.is_show_video_control) {
        plyr__controls[0].remove()
      }

      if(this.act?.theory?.file_variants?.length > 0) {

        // add selected to resolutions
        this.resolutions = this.act?.theory?.file_variants.map((item) => {
          item.selected = item.label == this.resolution;
          return item;
        });

        const video = this.resolutions.find((val) => val.label == this.resolution);
        if(video) {
          this.videoUrl = video.url;
        }
      }else{ 
        this.videoUrl =  this.act?.theory?.file?.url;
      }
    },
    confirmationResolution(resolution) {
      if(this.resolution == resolution.label) return;
      this.resolutionNew = resolution.label;
      this.dialog = true;
      this.$refs.plyr.player.pause();
    },
    changeResolution(resolution) {
      // check if resolution is same
      this.resolution = resolution;
      this.resolutions = this.act?.theory?.file_variants?.map((item) => {
        item.selected = item.label == resolution;
        // this.videoUrl = item.selected ? item.url : this.videoUrl;
        return item;
      });

      const video = this.resolutions.find((val) => val.label == resolution);

      if(video) {
        this.videoUrl = video.url;
      }
      
      // change video source
      this.$refs.plyr.player.source = {
        type: "video",
        sources: [
          {
            src: this.videoUrl,
            type: "video/mp4",
            size: this.resolution == "original" ? 720 : this.resolution,
          },
          
        ],
      };
      
      const plyr__controls = document.getElementsByClassName('plyr__progress__container');
      
      if(!this.act?.settings?.is_show_video_control) {
        plyr__controls[0].remove()
      }

      this.dialog = false;
    },
    useOnMoveTab() {
      document.onblur = () => {
        this.$refs.plyr.player.pause();
        // console.log('blur')
      };
    },
    useOnMoveMinimize() {
      document.onvisibilitychange = () => {
        this.$refs.plyr.player.pause();
        // console.log('minimes')
      };
    },
    handleEnded() {
      // console.log('ended video data', e);
      this.$emit("ended", this.act.is_complete);
    },
    date(a) {
      return a ? moment(a).locale("en-gb").format("L") : null;
    },
    sortTimes(times) {
      return times.sort((a, b) => {
        return b.start_time < a.start_time ? 1 : -1;
      });
    },
    day(day) {
      const days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const hari = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      return hari[days.findIndex((v) => v == day)];
    },
  },
};
</script>